@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

.spot-info {
  margin-bottom: 20px;
}

.spot-info h2 {
  font-size: 1.8rem;
  color: #333;
}

.spot-info .modal-detail {
  margin-bottom: 8px;
  color: #555;
}

.user-info {
  margin-bottom: 20px;
}

.user-info .modal-detail {
  margin-bottom: 8px;
  color: #555;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  width: 120px; /* Adjust the width as needed */
  margin-bottom: 10px; /* Add spacing between the buttons */
}

.actions .modal-close-button {
  background-color: #3f83f8;
}

.actions .modal-reserve-button {
  background-color: #f02d3a;
}

.actions .modal-open-button {
  background-color: #3fbf3f;
}




.log-message {
  text-align: center;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.phone-input {
  padding-left: 5000px;
}

.modal-content {
  height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-detail {
  margin-bottom: 0.5em;
}

.gate-log-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 90%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  outline: none;
}


.gate-log-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.log-message {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.log-message p {
  margin-bottom: 4px;
}

.log-message .timestamp {
  font-weight: bold;
}

.log-message .user {
  color: #666;
}



.date-picker-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 800px; /* Adjust the max-width value as needed */
}


.date-picker-calendar {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  max-width: 800px; /* Adjust the max-width value as needed */
  width: 100%;
}

.date-picker {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  max-width: 800px; /* Adjust the max-width value as needed */
}

